import React, { createContext, useContext, useEffect, useState } from 'react'
import { LocationContext } from "../common/LocationContext";
import { LocationClient, Menu } from "@food2room/clients";
import config from '../../config';

const Context = createContext({})

const Provider = ({ children }) => {
  const [menu, setMenu] = useState()
  const { location, menuIdLookingAt } = useContext(LocationContext)

  useEffect(() => {
    if (location !== undefined && !menuIdLookingAt) {
      LocationClient.setBaseUrl(config.baseOrderUrl)

      LocationClient.getLiveAndActiveMenus(location.locationId).then((menus) => {
        // TODO - this is hardcoded to get the first menu
        const m = Object.values(menus)[0]
        const menu = new Menu(m)
        setMenu(menu)
      })
    }

    if (location !== undefined && menuIdLookingAt) {
      LocationClient.getMenu(location.locationId, menuIdLookingAt, '').then(menu => {
        setMenu(menu)
      })
    }
    // eslint-disable-next-line
  }, [location])

  function getItemsForSection(section) {
    let itemsInSection = []
    for (const [key, value] of Object.entries(menu.items)) {
      if (value.section.toLowerCase() === section.toLowerCase()) {
        itemsInSection.push({ id: key, ...value })
      }
    }
    return [...itemsInSection]
  }

  const menuContext = {
    menu,
    getItemsForSection,
  }

  return <Context.Provider value={menuContext}>{children}</Context.Provider>
}

const { Consumer } = Context

export const MenuContext = Context
export const MenuContextProvider = Provider
export const MenuContextConsumer = Consumer
