import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import Modal from 'react-modal'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import AllItems from './AllItems'
import ItemModal from '../common/ItemModal'
import priceToString from '../../util/priceToString'

const Items = ({ sections }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const { url } = useRouteMatch()

  function openModal(item) {
    setSelectedItem(item)
    setIsModalOpen(true)
  }

  const BodyElement = () => {
    if (url === '/order/all') return <AllItems sections={sections} openModal={openModal} />
    return (
      <div className="margin-top">
        {sections.map(s => {
          if (s.href !== url) return null
          return (
            <React.Fragment key={s.title}>
              {s.items.map(item => {
                const cardClass = classNames('item-card', {
                  'item-card--disabled': !item.product.available,
                })

                const outOfStockClass = classNames('out-of-stock', {
                  'display--none': item.product.available,
                })

                function checkIfCanOpenModal() {
                  if (item.product.available) {
                    openModal(item)
                  }

                  return false
                }

                return (
                  <div className={cardClass} onClick={() => checkIfCanOpenModal()} key={item.itemId}>
                    <span className="title">{item.product.name}</span>
                    <span className="description">{item.product.description}</span>
                    <span className="price">{priceToString(item.product.sizes[0].price)}</span>
                    <span className={outOfStockClass}><FontAwesomeIcon icon={faTimesCircle} /> Out of stock</span>
                  </div>
                )
              })}
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  return (
    <>
      <BodyElement />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        appElement={document.getElementById('root')}
      >
        <ItemModal
          item={selectedItem}
          close={() => setIsModalOpen(false)}
        />
      </Modal>
    </>
  )
}

export default Items
