import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const OrderPlacedView = () => {
  return (
    <div className="success-payment-wrapper">
      <FontAwesomeIcon icon={faCheck} />
      <span>Your order was successful, it will be with you soon</span>
      <Link to="/order">
        <button type="button" className="button--secondary" id="btn-back-to-products">
          Back to menu
        </button>
      </Link>
    </div>
  )
}

export default OrderPlacedView
