import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker'

import "react-datepicker/dist/react-datepicker.css";

const EventBookingDetails = ({ eventDates, partyNameRef, eventDateRef, specialNotesRef, customDataFields, setRef }) => {
  return (
    <section>
      <h2>Booking details</h2>
      <section className="event-payment__form">
        <label>Party name</label>
        <input type="text" placeholder="J. Smith" ref={partyNameRef} />

        <DateSelect eventDates={eventDates} eventDateRef={eventDateRef} />

        {customDataFields.map(f => {
          return (
            <div key={f.id}>
              <label>{f.label}</label>
              <input type={f.type} placeholder={f.label} ref={setRef(f.id)} />
            </div>
          )
        })}

        <label>Notes/special requirements</label>
        <textarea placeholder="If you have any special requirements or notes you would like to pass to the event organiser, enter them here." ref={specialNotesRef} />
      </section>
    </section>
  )
}

const DateSelect = ({ eventDates = [], eventDateRef }) => {
  const [selectedDate, setSelectedDate] = useState()

  useEffect(() => {
    if (eventDates.length === 1) {
      const singleDate = new Date(eventDates[0].start)
      eventDateRef.current = singleDate
      setSelectedDate(singleDate)
    }
  }, [])

  const updateDate = (date) => {
    eventDateRef.current = date
    setSelectedDate(date)
  }

  const getEventDates = () => {
    return eventDates.map(d => new Date(d.start))
  }

  if (eventDates.length === 1) return null

  return (
    <>
      <label>Event date</label>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={selectedDate}
        onChange={updateDate}
        includeDates={getEventDates()}
        placeholderText="Select a date"
        disabled={eventDates.length === 1}
        withPortal
        onFocus={(e) => e.target.readOnly = true}
      />
    </>
  )
}

export default EventBookingDetails
