import { useEffect, useState } from "react"
import Loading from "components/common/Loading"
import { TagsDisplayLabels } from "enums/TagsDisplayLabels.enum"
import EventTicketList from "./EventTicketList"
import EventTicket from "./EventTicket"

const EventFoodList = ({ items, heading }) => {
    const [sortedItems, setSortedItems] = useState({})

    // Sort the food by tags
    useEffect(() => {
        const sortedItemsLocal = {}
        items.forEach(item => {
            const displayTags = item?.product?.category?.tags?.display?.length ? item?.product?.category?.tags?.display : ['untagged']
            displayTags.forEach(tag => {
                if (sortedItemsLocal[tag]) sortedItemsLocal[tag].push(item)
                else sortedItemsLocal[tag] = [item]
            })
        })

        setSortedItems(sortedItemsLocal)
    }, [items])

    if (Object.keys(sortedItems).length === 0) return <Loading />

    return (
        <div>
            <h2 className="margin-top--2 capitalise">{heading}</h2>
            {
                Object.keys(sortedItems).map(tag => {
                    if (tag.toLowerCase() === 'wine_red' || tag.toLowerCase() === 'wine_white' || tag.toLowerCase() === 'wine_rosé' || tag.toLowerCase() === 'wine_sparkling' || tag.toLowerCase() === 'soft_drink' || tag.toLowerCase() === 'beer') return null
                    return (
                        <div key={tag}>
                            <h3>{TagsDisplayLabels[tag]}</h3>
                            {sortedItems[tag].map(item => <EventTicket ticket={item} key={item.id} />)}
                        </div>
                    )
                })
            }
            <EventTicketList items={sortedItems['wine_red']} heading={TagsDisplayLabels['wine_red']} />
            <EventTicketList items={sortedItems['wine_white']} heading={TagsDisplayLabels['wine_white']} />
            <EventTicketList items={sortedItems['wine_rosé']} heading={TagsDisplayLabels['wine_rosé']} />
            <EventTicketList items={sortedItems['wine_sparkling']} heading={TagsDisplayLabels['wine_sparkling']} />
            <EventTicketList items={sortedItems['beer']} heading={TagsDisplayLabels['beer']} />
            <EventTicketList items={sortedItems['soft_drink']} heading={TagsDisplayLabels['soft_drink']} />
        </div>
    )
}

export default EventFoodList
