import { useCallback } from "react"

import { TagsDisplayLabels } from 'enums/TagsDisplayLabels.enum'

const EventTicketAllergens = ({ allergens = {} }) => {
  const mapAllergenKeysToText = useCallback(() => {
    const allergensInItem = []
    for (const [key, value] of Object.entries(allergens)) {
      if (value === true) allergensInItem.push(TagsDisplayLabels[key])
    }
    return allergensInItem.join(', ')
  }, [allergens])

  if (!mapAllergenKeysToText().length) return null

  return (
    <div className="event-ticket__allergens">
      <span className="event-ticket__allergens-label">Allergen notice</span>
      <span>This product contains {mapAllergenKeysToText()}.</span>
    </div>
  )
}

export default EventTicketAllergens
