import React, { useEffect, useState, useContext } from 'react'
import { LocationClient, OrderDestinationClient } from "@food2room/clients"
import { useHistory, useLocation } from 'react-router-dom'

import './check-in.scss'
import CheckInCard from './CheckInCard'
import Header from './Header'
import config from '../../config'
import Footer from './Footer'
import { LocationContext } from '../common/LocationContext'

const CheckInPage = () => {
  const [isCheckingCode, setIsCheckingCode] = useState(false)
  const [isCheckingCodeFromUrl, setIsCheckingCodeFromUrl] = useState(false)
  const [error, setError] = useState()

  const { setLocationId, setMenuIdLookingAt } = useContext(LocationContext)
  const { search } = useLocation()
  const history = useHistory()

  useEffect(() => {
    LocationClient.setBaseUrl(config.baseOrderUrl)
    OrderDestinationClient.setBaseUrl(config.baseOrderUrl)
  }, [])

  useEffect(() => {
    setIsCheckingCodeFromUrl(true)

    const checkUserInFromUrl = async () => {
      const query = new URLSearchParams(search)
      if (query.has('orderDestination')) {
        const qr = await getQrCodeDetails(query.get('orderDestination'))
        if (qr) checkIn(qr)
        else setIsCheckingCodeFromUrl(false)
      } else {
        setIsCheckingCodeFromUrl(false)
      }
    }

    checkUserInFromUrl()
    // eslint-disable-next-line
  }, [search, setLocationId, history, setMenuIdLookingAt])

  async function enterCode(code) {
    if (code.length !== 6) return
    setIsCheckingCode(true)
    const qr = await getQrCodeDetails(code)
    if (qr) checkIn(qr)
    else setIsCheckingCode(false)
  }

  async function getQrCodeDetails(id) {
    const res = await OrderDestinationClient.get(id).catch(e => {
      setError('invalid qr')
      return
    })
    if (res) return res
  }

  async function checkIn(qr) {
    const { locationId: id, resourceType, resourceId } = qr

    if (!id) return

    if (resourceType?.toLowerCase() === 'menu') {
      setLocationId(id)
      setMenuIdLookingAt(resourceId)

      // TODO - there is some duplication here as the menu isn't saved
      const menu = await LocationClient.getMenu(id, resourceId, '')

      if (menu.menuType?.toLocaleLowerCase() === 'event') {
        history.push('/event')
      } else {
        history.push('/order')
      }
    } else {
      const activeMenus = await LocationClient.getLiveAndActiveMenus(id)
      if (Object.keys(activeMenus).length > 0) {
        setLocationId(id)
        history.push('/order')
      } else {
        setError('no menus')
        setIsCheckingCode(false)
      }
    }
  }

  async function processQrScan(e) {
    if (!e) return
    // TODO - set loading
    const code = e.split('?')[1].replace('orderDestination=', '')
    const qr = await getQrCodeDetails(code)
    if (qr) checkIn(qr)
  }

  return (
    <div className="check-in-page__root">
      <div className="check-in-page__layout">
        <div className="check-in-page__container">
          <div className="check-in-page__form">
            <Header />
            <CheckInCard enterCode={enterCode} isCheckingCode={isCheckingCode} error={error} processQrScan={processQrScan} isCheckingCodeFromUrl={isCheckingCodeFromUrl} />
            <div className='check-in-page__spacer'></div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckInPage
