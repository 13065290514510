import React from 'react'
import classNames from 'classnames'

const Loading = ({ fullscreen }) => {
  const wrapperClass = classNames({
    'lds-fullscreen': fullscreen,
  })

  return (
    <div className={wrapperClass}>
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default Loading
