import React, { useRef, useState, useContext } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

import priceToString from '../../util/priceToString'
// import animatedCoffeeMachineGif from '../../assets/images/icons/animated_coffee_machine.gif'
import { LocationContext } from "../common/LocationContext";
import usePayment from '../../hooks/usePayment/usePayment'

const pageViews = { FORM: 'form', PROCESSING_PAYMENT: 'processing-payment' }

const CheckoutView = ({ getTotalPrice, placeOrder }) => {
  const { location } = useContext(LocationContext)

  if (!location) {
    return null
  }

  const Body = () => {
    const stripePublicKey = location.config.stripePublicKey
    const stripePromise = loadStripe(stripePublicKey)
    return (
      <Elements stripe={stripePromise}>
        <PaymentForm getTotalPrice={getTotalPrice} placeOrder={placeOrder} />
      </Elements>
    )
  }

  return (
    <div className="margin-bottom--2">
      <h1>Payment</h1>
      <Body />
    </div>
  )
}

const PaymentForm = ({ getTotalPrice, placeOrder }) => {
  const [pageView,] = useState(pageViews.FORM)
  const [error, setError] = useState(null)
  const stripe = useStripe()
  const elements = useElements()

  const nameRef = useRef()
  const emailRef = useRef()
  const termsRef = useRef()
  const tableNumberRef = useRef()

  const {
    isLoading: isProcessingPayment,
    error: paymentError,
    makePayment,
  } = usePayment()

  async function processPayment(event) {
    event.preventDefault()
    if (!stripe || !elements) return

    if (!nameRef.current.value || !emailRef.current.value || !tableNumberRef.current.value) {
      setError('All fields are required')
      return
    }

    if (!termsRef.current.checked || termsRef.current.checked === false) {
      setError('Please read and accept the terms and conditions')
      return
    }

    const customerDetails = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      tableNumber: tableNumberRef.current.value,
    }

    const cardNumber = elements.getElement(CardNumberElement)
    const orderRes = await placeOrder(customerDetails)

    if (orderRes.orderId && orderRes.intentId) {
      await makePayment(orderRes.intentId, cardNumber, customerDetails)
    } else {
      setError('There was an error with creating your order. Please try again.')
    }
  }

  const Error = () => {
    if (!error && !paymentError) return null
    return (
      <div className="p--error">
        <b>Error</b><br />
        {error || paymentError.message}
      </div>
    )
  }

  if (pageView === pageViews.FORM) {
    return (
      <form className='event-payment__form' onSubmit={processPayment}>
        <p className="secure-payment"><FontAwesomeIcon icon={faLock} />This is a secure payment</p>
        <Error />

        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" placeholder="John Smith" ref={nameRef} disabled={isProcessingPayment} />

        <label htmlFor="name">Email</label>
        <input type="email" placeholder="j.smith@company.com" ref={emailRef} disabled={isProcessingPayment} />

        <label htmlFor="name">Table number</label>
        <input type="number" placeholder="0" ref={tableNumberRef} disabled={isProcessingPayment} />

        <label htmlFor="cardNumber">Card number</label>
        <CardNumberElement
          id='cardNumber'
        />

        <div className="input--2">
          <div className="input--2-wrapper margin--right">
            <label htmlFor="expiryDate">Expiry date</label>
            <CardExpiryElement id='expiryDate' />
          </div>
          <div className="input--2-wrapper">
            <label htmlFor="cvc">CVC</label>
            <CardCvcElement id='cvc' />
          </div>
        </div>

        <div className="terms-wrapper">
          <input type="checkbox" id="terms" name="terms" ref={termsRef} disabled={isProcessingPayment} />
          <label htmlFor="terms">I confirm that I have read and accept the <a href="https://servd.uk/terms-of-service" target="_blank" rel="noreferrer">terms of service</a> and the <a href="https://servd.uk/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a>.</label>
        </div>

        {isProcessingPayment ? <div className="payment-info">Do not close this page until your payment has been completed.</div> : ''}
        {/* TODO - this needs to match table service style */}
        <input
          type="submit"
          value={isProcessingPayment ? `Pay - ${priceToString(getTotalPrice())}` : 'Processing payment...'}
          className="button--full-width margin-top"
          disabled={isProcessingPayment}
          id="submit-payment-btn"
        />
      </form>
    )
  }

  // if (isProcessingPayment) {
  //   return (
  //     <div className="processing-payment-wrapper">
  //       <img src={animatedCoffeeMachineGif} alt="processing payment" />
  //       <span>Processing your payment</span>
  //     </div>
  //   )
  // }

  return null
}

export default CheckoutView
