import { useContext } from "react"
import { faEnvelope, faMapMarkerAlt, faPhone, faUser } from "@fortawesome/free-solid-svg-icons"
import StarterKit from "@tiptap/starter-kit";
import { Link } from "@tiptap/extension-link";
import { LinkBubbleMenuHandler } from "mui-tiptap";
import { RichTextReadOnly } from "mui-tiptap";
import { LocationContext } from 'components/common/LocationContext'
import { getEventDates } from "util/event.util"

import EventDetailsCardLine from './EventDetailsCardLine'

const CustomLinkExtension = Link.extend({
  inclusive: false,
});

export const retExtensions = [
  StarterKit.configure({
      blockquote: false,
      bulletList: false,
      code: false,
      codeBlock: false,
      heading: false,
      horizontalRule: false,
      listItem: false,
      orderedList: false,
      strike: false,
  }),
  CustomLinkExtension.configure({
      autolink: true,
      linkOnPaste: true,
      openOnClick: false,
  }),
  LinkBubbleMenuHandler,
];

const EventDetailsCard = ({ orderScrollRef, eventMenu }) => {
  const { location } = useContext(LocationContext)

  function scrollToEventTickets() {
    if (!orderScrollRef) return
    else orderScrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <>
      <div className='event-details__layout' data-testid="event-details-card">
        <div className='event-details__card-wrapper'>
          <div className='event-details__card-top'>
            <div>
              <h1 data-testid="event-details-title">{eventMenu.name}</h1>
            </div>
            <EventDetailsCardLine icon={faMapMarkerAlt} value={eventMenu.eventDetails.eventLocation || location.name} label="Location" />
            {!eventMenu.depositPerItem && <EventDetailsCardLine icon={faMapMarkerAlt} value={getEventDates(eventMenu)} label="Date and time" />}
          </div>

          <div className='event-details__card-mid'>
            <div className='event-details__card-mid-dot-first'></div>
            <div className='event-details__card-mid-separator'></div>
            <div className='event-details__card-mid-dot-last'></div>
          </div>

          <div className='event-details__card-bottom'>
            <h2>Event organiser</h2>
            <EventDetailsCardLine icon={faUser} value={eventMenu.eventDetails.contactName || location.name} label="Contact name" />
            {!!eventMenu.eventDetails.contactPhone && <EventDetailsCardLine icon={faPhone} value={eventMenu.eventDetails.contactPhone} label="Phone" />}
            {!!eventMenu.eventDetails.contactEmail && <EventDetailsCardLine icon={faEnvelope} value={eventMenu.eventDetails.contactEmail} label="Email" />}
            <button className="show-for-mobile-only" data-testid="btn-buy-tickets" onClick={scrollToEventTickets}>Go to order</button>
          </div>

        </div>
      </div>

      <div className="event-details__description margin-top--4">
        <h2>Event details</h2>
        <RichTextReadOnly content={eventMenu.description} extensions={retExtensions} />
      </div>
    </>
  )
}

export default EventDetailsCard
