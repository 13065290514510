import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const EventDetailsCardLine = ({ icon, label, value }) => {
  return (
    <div data-testid={`event-details-${label.toLowerCase().replace(/\s+/g, '-')}`}>
      <div className="event-details__line-mobile">
        <FontAwesomeIcon icon={icon} data-testid={`${icon}-icon-mobile`} />
        <span>{value}</span>
      </div>

      <div className="event-details__line-desktop">
        <div>
          <FontAwesomeIcon icon={icon} data-testid={`${icon}-icon`} />
          <span className="event-details__line-desktop-label">{label}</span>
        </div>
        <span className="event-details__line-desktop-value">{value}</span>
      </div>
    </div>
  )
}

export default EventDetailsCardLine
