import { useState, useRef, useContext, useEffect } from 'react'
import { LocationClient } from "@food2room/clients"
import { useHistory } from 'react-router-dom'
import useDynamicRefs from 'use-dynamic-refs';

import EventDetailsCard from 'components/event/EventDetailsCard'
import EventTickets from './EventTickets'
import Hero from '../../common/Hero'
import './event.scss'
import EventPayment from './EventPayment'
import { BasketContext } from '../../basket/BasketContext'
import { LocationContext } from '../../common/LocationContext'
import config from '../../../config'
import Footer from '../../check-in/Footer'
import Loading from '../../common/Loading'
import { isEventOver } from '../../../util/event.util'

const EventPage = () => {
  const PAGE_VIEWS = { TICKETS: 'tickets', PAYMENT: 'payment' }
  const [pageView, setPageView] = useState(PAGE_VIEWS.TICKETS)
  const orderScrollRef = useRef()
  const [ticketError, setTicketError] = useState()
  const [eventMenu, setEventMenu] = useState()
  const [getRef, setRef] =  useDynamicRefs();

  const { basket } = useContext(BasketContext)
  const { menuIdLookingAt, location } = useContext(LocationContext)
  const history = useHistory()

  const partyNameRef = useRef()
  const eventDateRef = useRef(0)
  const specialNotesRef = useRef()
  const customDataRef = useRef()

  useEffect(() => {
    if (location && menuIdLookingAt) {
      LocationClient.setBaseUrl(config.baseOrderUrl)
      LocationClient.getMenu(location.locationId, menuIdLookingAt, '')
        .then(setEventMenu)
    }

    if (location && !menuIdLookingAt) {
      history.push('/checkin')
    }
    // eslint-disable-next-line
  }, [menuIdLookingAt, location])

  function isViewing(view) {
    return pageView === view
  }

  function goToCheckout() {
    // Check user has filled in booking details
    if (!partyNameRef.current.value || partyNameRef.current.value.length === 0 || eventDateRef.current === 0) {
      setTicketError('Fill in your booking details.')
      return
    }

    // Check for any required custom fields
    if (eventMenu.customDataFields) {
      for (const f of eventMenu.customDataFields) {
        if (f.isRequired && (!getRef(f.id).current.value || getRef(f.id).current.value.length === 0)) {
          setTicketError('Fill in your booking details.')
          return
        }
      }
    }

    // Check user has added item to basket
    if (Object.keys(basket.contents).length === 0) {
      setTicketError('Select at least one item before proceeding with payment.')
      return
    }

    // Check for minimum order quantity
    let hasMinQtyError = false
    Object.values(basket.contents).forEach(item => {
      if (item.quantity < item.minimumOrderQuantity) {
        setTicketError(`Cannot buy less than ${item.minimumOrderQuantity} of ${item.product.name}`)
        hasMinQtyError = true
      }
    });
    if (hasMinQtyError) {
      return
    }

    // Save custom data
    customDataRef.current = []
    if (eventMenu.customDataFields) {
      eventMenu.customDataFields.forEach(f => {
        customDataRef.current.push({ ...f, value: getRef(f.id).current.value })
      });
    }

    // All good, clear error and proceed
    if (ticketError) setTicketError(null)
    setPageView(PAGE_VIEWS.PAYMENT)
  }

  function goToTickets() {
    setPageView(PAGE_VIEWS.TICKETS)
  }

  if (!eventMenu) return <Loading fullscreen={true} />

  return (
    <>
      <Hero imageUrl={eventMenu?.image?.coverUrl} />
      <div className='container event-page__container-override'>
        <div className="event-page__layout">
          <div className='event-page__wrapper'>
            <EventDetailsCard orderScrollRef={orderScrollRef} eventMenu={eventMenu} />
            {
              isEventOver(eventMenu)
                ?
                <EventEndedNotice />
                :
                <>
                  {isViewing(PAGE_VIEWS.TICKETS) && <EventTickets goToCheckout={goToCheckout} orderScrollRef={orderScrollRef} error={ticketError} eventMenu={eventMenu} partyNameRef={partyNameRef} eventDateRef={eventDateRef} specialNotesRef={specialNotesRef} setRef={setRef} />}
                  {isViewing(PAGE_VIEWS.PAYMENT) && <EventPayment goToTickets={goToTickets} orderScrollRef={orderScrollRef} eventMenu={eventMenu} partyName={partyNameRef.current.value} eventDate={eventDateRef.current} specialRequirements={specialNotesRef.current.value} customData={customDataRef.current} />}
                </>
            }
          </div>
        </div>
      </div>
      <div className='container'>
        <Footer />
      </div>
    </>
  )
}

const EventEndedNotice = () => (
  <div className="event-tickets__layout margin-bottom margin-top--2">
    <h2 className='margin-bottom--none'>This event is now over</h2>
    <p>This event has now ended. Please contact the event organiser for future events.</p>
  </div>
)

export default EventPage
