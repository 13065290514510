const EventTicketAvailability = ({ stockLeft }) => {
  if (stockLeft === 0) {
    return <span className="event-ticket__details__available event-ticket__details__available--gone">Sold out</span>
  }

  if (stockLeft && stockLeft <= 50) {
    return <span className="event-ticket__details__available event-ticket__details__available--limited">Only {stockLeft} remaining</span>
  }

  return <span className="event-ticket__details__available">Available</span>
}

export default EventTicketAvailability
