import React from 'react'
import Logo from '../common/Logo'

import './check-in.scss'

const Header = () => (
  <div className="check-in-page__header">
    <Logo />
  </div>
)

export default Header
