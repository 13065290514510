import ReactTooltip from 'react-tooltip'

const Tooltip = ({ itemId, text }) => (
  <ReactTooltip
    id={itemId}
    type='dark'
    effect='solid'
    key={`${itemId}-tooltip`}
    place="top"
    overridePosition={(
      { left, top },
      currentEvent, currentTarget, node) => {
      const d = document.documentElement;
      left = Math.min(d.clientWidth - node.clientWidth, left);
      top = Math.min(d.clientHeight - node.clientHeight, top);
      left = Math.max(0, left);
      top = Math.max(0, top);
      return { top, left }
    }
    }
  >
    <span>{text}</span>
  </ReactTooltip>
)

export default Tooltip
