const envs = {
    dev: {
        baseOrderUrl: "https://api.dev.servd.uk",
        mockClients: false,
    },
    prod: {
        baseOrderUrl: "https://api.prod.servd.uk",
        mockClients: false,
    },
    local: {
        baseOrderUrl: process.env.REACT_APP_BASE_ORDER_URL,
        mockClients: process.env.REACT_APP_MOCK_CLIENTS,
    },
}

let config
const stage = process.env.REACT_APP_STAGE

if (stage && envs.hasOwnProperty(stage)) {
    config = envs[stage]
} else {
    config = envs.local
}


export default config;