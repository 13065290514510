import moment from 'moment'

export function getEventDates(event) {
  if (!event?.eventDetails?.eventDateTime || event?.eventDetails?.eventDateTime.length === 0) {
    return 'This event has no specified date.'
  }

  const minDate = getEventFirstDate(event)
  const maxDate = getEventLastDate(event)

  if (minDate.format('DD MMM YYYY') === maxDate.format('DD MMM YYYY')) {
    return `${minDate.format('DD MMM YYYY')}, ${minDate.format('HH:mm')}-${maxDate.format('HH:mm')}`
  } else {
    return `${minDate.format('DD MMM YYYY HH:mm')} - ${maxDate.format('DD MMM YYYY HH:mm')}`
  }
}

export function isEventOver(event) {
  if (!event?.eventDetails?.eventDateTime || event?.eventDetails?.eventDateTime.length === 0) {
    return false
  }

  const maxDate = getEventLastDate(event)
  const today = moment()
  return moment(today).isAfter(maxDate)
}

export function getEventFirstDate(event) {
  const moments = getAllMoments(event)
  return moment.min(moments)
}

export function getEventLastDate(event) {
  const moments = getAllMoments(event)
  return moment.max(moments)
}

function getAllMoments(event) {
  const moments = []
  event.eventDetails.eventDateTime.forEach(d => {
    moments.push(moment(d.start))
    moments.push(moment(d.end))
  })
  return moments
}
