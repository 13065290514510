import priceToString from "util/priceToString"

const DepositBanner = ({ depositAmount, eventDates = [] }) => {
  if (!depositAmount) return null

  return (
    <div className="deposit-banner">
      <h2>Split the cost</h2>
      <p>Only pay a {priceToString(depositAmount)} deposit per ticket today. Select your payment option at checkout. Full amount must be settled by {new Date(eventDates[0].start).toLocaleDateString()}.</p>
    </div>
  )
}

export default DepositBanner
