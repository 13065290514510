import React, { createContext, useEffect, useRef, useState } from 'react'
import Basket from "./Basket";

const Context = createContext({})

const Provider = ({ children }) => {
  const localStorageKey = 'basket'
  const localStorageAgeKey = 'basket_age'
  const [basket, setBasket] = useState(new Basket())
  const numberOfItemsInBasketByRef = useRef(0)

  useEffect(() => {
    const lsBasket = JSON.parse(localStorage.getItem(localStorageKey))
    const lsAge = JSON.parse(localStorage.getItem(localStorageAgeKey))

    // Basket expires after 24 hours
    const basketHasExpired = () => lsAge.last_updated < Date.now() - 24 * 60 * 60 * 1000

    if (lsAge && lsBasket && !basketHasExpired()) {
      const basket = new Basket(lsBasket)
      setBasket(basket)
      numberOfItemsInBasketByRef.current = basket.countItems()
    } else {
      setBasket(new Basket())
    }
  }, [])

  // TODO - we probably don't want this for events - revisit later?
  // useEffect(() => {
  //   function writeBasketToLocalStorage(basket) {
  //     localStorage.setItem(localStorageKey, JSON.stringify(basket.contents))
  //     localStorage.setItem(localStorageAgeKey, JSON.stringify({ last_updated: Date.now() }))
  //   }

  //   writeBasketToLocalStorage(basket)
  // }, [basket])

  function addToBasket(item, size, quantity) {
    basket.add(item, size, quantity)
    setBasket(basket.copy())
    numberOfItemsInBasketByRef.current += quantity
  }

  function updateBasketItem(item, size, quantity) {
    basket.remove(item, size)
    basket.add(item, size, quantity)
    numberOfItemsInBasketByRef.current = basket.countItems()
    setBasket(basket.copy())
  }

  function removeItem(item, size) {
    basket.remove(item, size)
    numberOfItemsInBasketByRef.current = basket.countItems()
    setBasket(basket.copy())
  }

  function clearBasket() {
    setBasket(new Basket())
    numberOfItemsInBasketByRef.current = 0
  }

  function getQuantityOfItemInBasket(item, size) {
    const idOfItemSize = basket.getBasketId(item, size)
    if (basket.contents[idOfItemSize]) return basket.contents[idOfItemSize].quantity
    else return 0
  }

  const basketContext = {
    basket,
    numberOfItemsInBasketByRef,
    addToBasket,
    clearBasket,
    updateBasketItem,
    removeItem,
    getQuantityOfItemInBasket,
  }

  return <Context.Provider value={basketContext}>{children}</Context.Provider>
}

const { Consumer } = Context

export const BasketContext = Context
export const BasketContextProvider = Provider
export const BasketContextConsumer = Consumer