import React from 'react'

const Footer = () => (
  <div className='check-in-footer__layout'>
    <div className='check-in-footer__wrapper'>
      <a href="https://servd.uk/" target="_blank" rel="noreferrer">© Servd.</a>
      <span className='check-in-footer__separator'>.</span>
      <a href="https://servd.uk/#contact" target="_blank" rel="noreferrer">Contact</a>
      <span className='check-in-footer__separator'>.</span>
      <a href="https://servd.uk/terms-of-service" target="_blank" rel="noreferrer">Terms</a>
      <span className='check-in-footer__separator'>.</span>
      <a href="https://servd.uk/privacy-policy" target="_blank" rel="noreferrer">Privacy</a>
    </div>
  </div>
)

export default Footer
