import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, Redirect } from "react-router-dom"

import { MenuContext } from './MenuContext'
import LocalNav from './LocalNav'
import Items from './Items'
import Loading from '../common/Loading'

const OrderPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [sections, setSections] = useState([])
  const { menu, getItemsForSection } = useContext(MenuContext)

  useEffect(() => {
    setIsLoading(true)
    if (menu) {
      let sectionsToAdd = []

      if (menu.sections?.length > 0) {
        menu.sections.forEach(s => {
          sectionsToAdd.push({
            title: s.title,
            href: `/order/${s.title.toLowerCase()}`,
            items: getItemsForSection(s.title),
          })
        })
      } else {
        const defaultSections = ['Starters', 'Mains', 'Desserts', 'Drinks']
        defaultSections.forEach(section => {
          const itemsForSection = getItemsForSection(section.toLowerCase())
          if (itemsForSection.length > 0) {
            sectionsToAdd.push({ title: section, href: `/order/${section.toLowerCase()}`, items: itemsForSection })
          }
        })
      }

      setSections([{ title: 'All', href: '/order/all' }, ...sectionsToAdd])
    }
    setIsLoading(false)
  }, [menu, getItemsForSection])

  if (isLoading) {
    return <Loading fullscreen={true} />
  }

  return (
    <div className="container">
      <Switch>
        <Route path="/order/:id">
          <span className="order-page-title">Menu</span>
          <div className="order-page-wrapper">
            <div>
              <LocalNav sections={sections} />
            </div>
            <div>
              <Items sections={sections} />
            </div>
          </div>
        </Route>
        <Redirect from="/order" to="/order/all" />
      </Switch>
    </div>
  )
}

export default OrderPage
