import React, { useContext, useEffect, useState } from 'react'

import { BasketContext } from './BasketContext'
import { LocationContext } from '../common/LocationContext'
import { MenuContext } from '../order/MenuContext'
import { OrderClient } from "@food2room/clients"
import OrderingView from './OrderingView'
import OrderPlacedView from './OrderPlacedView'
import BasketView from './BasketView'
import CheckoutView from './CheckoutView'
import { OrderDto, OrderItemDto } from '@food2room/clients'
import config from '../../config'

const BasketPage = () => {
  const BASKET_STATUS = { BROWSING: 'browsing', ORDERING: 'ordering', ORDER_PLACED: 'order-placed', CHECKOUT: 'checkout' }
  const basketContext = useContext(BasketContext)
  const { location } = useContext(LocationContext)
  const { menu } = useContext(MenuContext)
  const [basketStatus, setBasketStatus] = useState(BASKET_STATUS.BROWSING)
  const [items, setItems] = useState({})

  useEffect(() => {
    const { basket: { contents: basket } } = basketContext
    let starters = [], mains = [], desserts = [], drinks = []
    const categoryToArrayMap = { 'starters': starters, 'mains': mains, 'desserts': desserts, 'drinks': drinks }
    for (const key in basket) {
      const item = basket[key]
      // TODO - this assumes that the first category is the main one
      categoryToArrayMap[item.product.category.displayCategory].push(item)
    }

    setItems({ starters: [...starters], mains: [...mains], desserts: [...desserts], drinks: [...drinks] })
  }, [basketContext])

  function getTotalPrice() {
    return Object.values(basketContext.basket.contents)
      .map(item => item.size.price * item.quantity)
      .reduce((a, b) => a + b, 0);
  }

  function goToCheckout() {
    setBasketStatus(BASKET_STATUS.CHECKOUT)
  }

  async function placeOrder(customer) {
    // setBasketStatus(BASKET_STATUS.ORDERING)
    const itemsEntries = Object.entries(basketContext.basket.contents).map(([key, value]) => {
      const orderItem = new OrderItemDto({ quantity: value.quantity, size: value.size, menuId: menu.id, menuItemId: value.id })
      return [key, orderItem]
    })

    const items = Object.fromEntries(itemsEntries)

    const customerDetails = {
      name: customer.name || '',
      email: customer.email || '',
    }

    const order = new OrderDto({
      locationId: location.locationId,
      customer: customerDetails,
      items: items,
      totalCost: getTotalPrice(),
      destination: `Table ${customer.tableNumber}`,
      menuId: '', // TODO
    })

    OrderClient.setBaseUrl(config.baseOrderUrl)
    const orderRes = await OrderClient.submitOrder(order)
      .catch(error => {
        return error
      })
    // .then(() => {
    //   basketContext.clearBasket()
    //   setBasketStatus(BASKET_STATUS.ORDER_PLACED)
    // })

    // TODO - error catching

    return orderRes
  }

  const Body = () => {
    if (basketStatus === BASKET_STATUS.BROWSING && (!items || basketContext.numberOfItemsInBasketByRef.current === 0)) {
      return (
        <div className="container">
          <p>Your basket is empty</p>
        </div>
      )
    }

    if (basketStatus === BASKET_STATUS.ORDERING) return <OrderingView />
    if (basketStatus === BASKET_STATUS.ORDER_PLACED) return <OrderPlacedView />
    if (basketStatus === BASKET_STATUS.CHECKOUT) return <CheckoutView getTotalPrice={getTotalPrice} placeOrder={placeOrder} />
    return <BasketView items={items} goToCheckout={goToCheckout} getTotalPrice={getTotalPrice} />
  }

  return (
    <div className="container margin-top--2" id="basket-container">
      <Body />
    </div>
  )
}

export default BasketPage
