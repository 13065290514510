import React, { createContext, useEffect, useState } from "react"
import { LocationClient } from "@food2room/clients";
import config from "../../config";

const Context = createContext({})

const localStorageLocationIdKey = 'locationId'
const storageMenuIdLookingAtKey = 'menuIdLookingAt'

const Provider = ({ children }) => {
  const [locationId, setLocationId] = useState(sessionStorage.getItem(localStorageLocationIdKey))
  const [location, setLocation] = useState(undefined)
  const [menuIdLookingAt, setMenuIdLookingAt] = useState(sessionStorage.getItem(storageMenuIdLookingAtKey))

  useEffect(() => {
    if (locationId === null || locationId === undefined) {
      setLocation(undefined)
    } else {
      LocationClient.setBaseUrl(config.baseOrderUrl)
      LocationClient.getLocation(locationId)
        .then(setLocation)
        .catch(e => {
          setLocation(undefined)
          setLocationId(undefined)
          console.error(e)
        })
    }
  }, [locationId])

  useEffect(() => {
    if (locationId === undefined || locationId === null) {
      sessionStorage.removeItem(localStorageLocationIdKey)
    } else {
      sessionStorage.setItem(localStorageLocationIdKey, locationId)
    }
  }, [locationId])

  useEffect(() => {
    if (menuIdLookingAt === undefined || menuIdLookingAt === null) {
      sessionStorage.removeItem(storageMenuIdLookingAtKey)
    } else {
      sessionStorage.setItem(storageMenuIdLookingAtKey, menuIdLookingAt)
    }
  }, [menuIdLookingAt])

  function clearLocation() {
    setLocationId(undefined)
  }

  function locationIsSet() {
    const locationId = sessionStorage.getItem(localStorageLocationIdKey)
    return locationId !== null
  }

  function isAccessingMenuDirectly() {
    const menuId = sessionStorage.getItem(storageMenuIdLookingAtKey)
    return menuId !== null
  }

  const locationContext = {
    location,
    menuIdLookingAt,
    setLocationId,
    clearLocation,
    locationIsSet,
    setMenuIdLookingAt,
    isAccessingMenuDirectly,
  }

  return <Context.Provider value={locationContext}>{children}</Context.Provider>
}

const { Consumer } = Context

export const LocationContext = Context
export const LocationContextProvider = Provider
export const LocationContextConsumer = Consumer
