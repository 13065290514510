import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltLeft, faShoppingBasket } from '@fortawesome/free-solid-svg-icons'
import { Link, useRouteMatch } from 'react-router-dom'

import { BasketContext } from '../basket/BasketContext'
import { LocationContext } from "./LocationContext"

const NavBar = () => {
  const basketContext = useContext(BasketContext)
  const { location, clearLocation } = useContext(LocationContext)

  const Title = () => {
    const { path } = useRouteMatch()
    if (!path.startsWith('/order')) {
      return <Link to="/order"><span className="title"><FontAwesomeIcon icon={faLongArrowAltLeft} /></span></Link>
    }

    return <span className="title">Order</span>
  }

  const BasketBadge = () => {
    if (basketContext.numberOfItemsInBasketByRef.current === 0) {
      return null
    }

    return <span className="badge">{basketContext.numberOfItemsInBasketByRef.current}</span>
  }

  return (
    <div className="hero-wrapper">
      <div className="container">
        <nav className="top-nav">
          <div className="heading-wrapper">
            <Title />
            {/* TODO - this needs an actual location context */}
            <span className="subtitle">{location?.name}</span>
            <span className="reset-location" onClick={clearLocation} data-cy={"change-location"}>Wrong place?</span>
          </div>
          <Link to="/basket">
            <div className="icons">
              <FontAwesomeIcon icon={faShoppingBasket} />
              <BasketBadge />
            </div>
          </Link>
        </nav>
      </div>
      <div className="container hero-content">
        <div className="hero-left">
          <span className="hero-title">The food you love</span>
          <span className="hero-subtitle">At your fingertips</span>
        </div>
      </div>
    </div>
  )
}

export default NavBar
