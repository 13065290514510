export const TagsDisplayLabels = {
  // Allergens
  'celery': 'celery',
  'gluten': 'cereals containing gluten',
  'crustaceans': 'crustaceans',
  'eggs': 'eggs',
  'fish': 'fish',
  'lupin': 'lupin',
  'milk': 'milk',
  'molluscs': 'molluscs',
  'mustard': 'mustard',
  'treeNuts': 'tree nuts',
  'peanuts': 'peanuts',
  'sesameSeeds': 'sesame seeds',
  'soybeans': 'soybeans',
  'sulphites': 'sulphur dioxide and sulphites',

  // Display tags
  'wine_red': 'Red wines',
  'wine_white': 'White wines',
  'wine_rosé': 'Rosé wines',
  'wine_sparkling': 'Sparkling wines',
  'soft_drink': 'Soft drinks',
  'beer': 'Beer',
}
