import React, { useState } from 'react'
import VerificationInput from "react-verification-input"
import QrReader from 'react-qr-reader'

import './check-in.scss'
import Loading from '../common/Loading'

const CheckInCard = ({ enterCode, isCheckingCode, error, processQrScan, isCheckingCodeFromUrl }) => {
  const [isScanningQrCode, setIsScanningQrCode] = useState(false)

  return (
    <div className="check-in-card__wrapper">
      <div className="check-in-card__container">
        <form onSubmit={e => e.preventDefault()}>
          <div>
            <span className='check-in-card__title'>Check-in</span>
          </div>
          <div>
            {!isCheckingCodeFromUrl && <p>To continue, please enter the provided 6 digit code or if available, scan the QR code.</p>}
            {isCheckingCodeFromUrl && <p>Please wait while we retrieve your information.</p>}
          </div>
          {!isCheckingCodeFromUrl &&
            <>
              <div className='check-in-card__code-wrapper'>
                <VerificationInput
                  onChange={enterCode}
                  removeDefaultStyles
                  autoFocus
                  classNames={{
                    container: "check-in-code__container",
                    character: "check-in-code__character",
                    characterInactive: "check-in-code__character--inactive",
                    characterSelected: "check-in-code__character--selected",
                  }}
                />
              </div>
              <div>
                {error === 'invalid qr' ? <p className="error-animated margin-bottom">Invalid code, please check the code and enter it again.</p> : null}
                {error === 'no menus' ? <p className="error-animated margin-bottom">The selected menu/event is not active. Please check with the company that gave you this code.</p> : null}
              </div>
              <div>
                {!isCheckingCode && <button type="submit" className='button--full-width'>Continue</button>}
                {isCheckingCode && <button className='button--full-width button--loading'><Loading /></button>}
              </div>
            </>
          }
        </form>
        {!isCheckingCodeFromUrl &&
          <div className='check-in-card__qr-wrapper'>
            {!isScanningQrCode && <button className='button--text' onClick={() => setIsScanningQrCode(true)}>Click to scan QR code</button>}
            {isScanningQrCode && <QRScannerComponent cancelScan={setIsScanningQrCode} processQrScan={processQrScan} />}
          </div>
        }
      </div>
    </div>
  )
}

const QRScannerComponent = ({ cancelScan, processQrScan }) => {
  return (
    <>
      <QrReader
        delay={300}
        onScan={processQrScan}
        style={{ width: '75%', height: '75%', margin: '1rem auto 0 auto' }}
      />
      <button className='button--text margin-top' onClick={() => cancelScan(false)}>Close scanner</button>
    </>
  )
}

export default CheckInCard
