import { useState, useEffect, useMemo } from 'react'

import EventTicketList from 'components/event/EventTicketList'
import EventFoodList from 'components/event/EventFoodList'
import EventBookingDetails from 'components/event/EventBookingDetails'
import DepositBanner from 'components/event/DepositBanner'
import priceToString from 'util/priceToString'

const EventTickets = ({ goToCheckout, orderScrollRef, error, eventMenu, partyNameRef, eventDateRef, specialNotesRef, setRef }) => {
  const [productsByType, setProductsByType] = useState({})

  // TODO - processing state
  useEffect(() => {
    const allProductsByType = {}
    Object.values(eventMenu.items).forEach(item => {
      if (allProductsByType[item.section]) {
        allProductsByType[item.section].push(item)
      } else {
        allProductsByType[item.section] = [item]
      }
    })

    setProductsByType(allProductsByType)
  }, [eventMenu.items])

  const hasFee = useMemo(() => {
    return !!eventMenu.defaultItemFee
  }, [eventMenu])

  const getFeeDisclaimer = () => {
    let disclaimer = `* ${priceToString(eventMenu.defaultItemFee)} booking fee is applied per ticket`

    if (eventMenu.maxTotalItemFee) {
      disclaimer += `, capped at ${priceToString(eventMenu.maxTotalItemFee)}.`
    } else {
      disclaimer += '.'
    }

    disclaimer += ' Fee is calculated on the checkout page.'

    if (eventMenu.depositPerItem) {
      disclaimer += " If you're splitting the cost, booking fees will not be charged on future payments."
    }

    return disclaimer
  }

  if (Object.keys(productsByType).length === 0 || eventMenu.active === false) {
    return (
      <div className="event-tickets__layout margin-bottom margin-top--2" ref={orderScrollRef}>
        <h2 className='margin-bottom--none'>Sorry, you're a bit early</h2>
        <p>Looks like you got here earlier than we thought you would! We're still setting up our event, please come back later.</p>
      </div>
    )
  }

  return (
    <div className="event-tickets__layout margin-bottom margin-top--2" ref={orderScrollRef}>
      <EventBookingDetails
        eventDates={eventMenu.eventDetails.eventDateTime}
        partyNameRef={partyNameRef}
        eventDateRef={eventDateRef}
        specialNotesRef={specialNotesRef}
        customDataFields={eventMenu.customDataFields ?? []}
        setRef={setRef}
      />
      <DepositBanner depositAmount={eventMenu.depositPerItem} eventDates={eventMenu.eventDetails.eventDateTime} />
      {!!productsByType['tickets'] && <EventTicketList items={productsByType['tickets']} heading="Tickets" hasFee={hasFee} />}
      {
        Object.keys(productsByType).map(products => {
          if (products.toLowerCase() === 'tickets') return null
          return <EventFoodList key={products} items={productsByType[products]} heading={products} />
        })
      }
      {!!error && <div className="error-wrapper"><p className="error error-animation">{error}</p></div>}
      <button id="event-checkout-btn" className="margin-top" onClick={goToCheckout}>Checkout</button>

      {hasFee && (<p>{getFeeDisclaimer()}</p>)}
    </div>
  )
}

export default EventTickets
