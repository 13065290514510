export default class Basket {
  contents = {}

  constructor(contents) {
    if (contents) {
      this.contents = contents;
    } else {
      this.contents = {}
    }
  }

  add(item, size, quantity) {
    const basketId = this.getBasketId(item, size)
    let newQuantity = quantity

    if (this.contents?.[basketId]) {
      newQuantity += this.contents[basketId].quantity
    }
    const newTotalPrice = size.price * newQuantity
    this.contents[basketId] = { ...item, size: size, quantity: newQuantity, totalPrice: newTotalPrice }
  }

  remove(item, size) {
    const basketId = this.getBasketId(item, size)
    delete this.contents[basketId]
  }

  getBasketId(item, size) {
    return `${item.id}-${size.name}`
  }

  countItems() {
    return Object.values(this.contents)
      .map(item => item.quantity)
      .reduce((a, b) => a + b, 0)
  }

  copy() {
    return new Basket(this.contents);
  }

  getTotalPrice() {
    return Object.values(this.contents)
        .map(item => item.size.price * item.quantity)
        .reduce((a, b) => a + b, 0);
  }
}