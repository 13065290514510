import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"

import NavBar from './components/common/NavBar'
import OrderPage from './components/order/OrderPage'
import BasketPage from './components/basket/BasketPage'
import { BasketContextProvider } from './components/basket/BasketContext'
import { MenuContextProvider } from './components/order/MenuContext'
import { LocationContext, LocationContextProvider } from './components/common/LocationContext'
import CheckInPage from './components/check-in/CheckInPage'
import EventPage from './components/order/events/EventPage'

import './assets/scss/app.scss'

function App() {
  return (
    <LocationContextProvider>
      <MenuContextProvider>
        <BasketContextProvider>
          <Router>
            <Switch>
              <Route path="/checkin">
                <CheckInPage />
              </Route>
              <PrivateRoute path="/order">
                <NavBar />
                <OrderPage />
              </PrivateRoute>
              <PrivateRoute path="/event">
                <EventPage />
              </PrivateRoute>
              <PrivateRoute exact path="/basket">
                <NavBar />
                <BasketPage />
              </PrivateRoute>
              <Redirect from="/" to="/checkin" />
            </Switch>
          </Router>
        </BasketContextProvider>
      </MenuContextProvider>
    </LocationContextProvider>
  )
}

const PrivateRoute = ({ children, ...rest }) => {
  const { locationIsSet } = useContext(LocationContext)
  return (
    <Route {...rest} render={() => {
      if (locationIsSet()) return children
      else return <Redirect to='/checkin' />
    }} />
  )
}

export default App
