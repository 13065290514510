import { faInfoCircle, faTicketAlt, faUtensils } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import uniqueId from "lodash.uniqueid"

import Tooltip from 'components/common/Tooltip'
import EventTicketSize from 'components/event/EventTicketSize'
import EventTicketAllergens from 'components/event/EventTicketAllergens'
import EventTicketAvailability from './EventTicketAvailability'

const EventTicket = ({ ticket, hasFee, minOrderQty }) => {
  const { product, section } = ticket
  const { sizes, name, description, available, stock } = product

  const isActuallyAvailable = available && stock !== 0

  // if (!available) return null

  return (
    <>
      <div className="event-ticket__layout event-ticket__wrapper">
        <div className="event-ticket__details">
          <div className="event-ticket__details__name-wrapper">
            <div className="event-ticket__details__icon">
              <FontAwesomeIcon icon={section.toLowerCase() === 'tickets' ? faTicketAlt : faUtensils} data-testid={`${ticket.id}-icon`} />
            </div>
            <div className="event-ticket__details__name">
              <span className="event-ticket__details__name-label">{section.toLowerCase() === 'tickets' ? 'Ticket' : 'Name'}</span>
              <span>{name}</span>
              {description?.length > 0 ? <span data-tip data-for={ticket.id} className="margin-left--half" data-testid={`${ticket.id}-desc`}><FontAwesomeIcon icon={faInfoCircle} /></span> : null}
            </div>
          </div>
          <div>
            <EventTicketAvailability stockLeft={isActuallyAvailable ? product.stock : 0} />
          </div>
        </div>
        {Boolean(description) && section.toLowerCase() !== 'tickets' && (
          <p className="event-ticket__details__description">{description}</p>
        )}
        <EventTicketAllergens allergens={ticket?.product?.category?.tags?.allergens} />
        {isActuallyAvailable && (
          <>
            <span className="event-ticket__hr" />
            {sizes.map(size => {
              const sizeId = uniqueId("item-size-")
              return <EventTicketSize sizeId={sizeId} size={size} item={ticket} key={sizeId} stockLeft={product.stock} hasFee={hasFee} minOrderQty={minOrderQty} available={available} />
            })}
          </>
        )}
      </div>
      <Tooltip itemId={ticket.id} text={description} />
    </>
  )
}

export default EventTicket
