import React, { useState, useContext } from 'react'
import Modal from 'react-modal'

import priceToString from '../../util/priceToString'
import ItemModal from '../common/ItemModal'
import { LocationContext } from '../common/LocationContext'

const BasketView = ({ items, goToCheckout, getTotalPrice }) => {
  const { location } = useContext(LocationContext)

  const OrderLine = ({ item }) => {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    const displaySize = item.product.sizes.length > 1 ? `(${item.size.name})` : "";
    return (
      <>
        <div className="basket-item" onClick={() => setIsEditModalOpen(true)}>
          <div className="basket-item-details">
            <span className="basket-item-quantity">{item.quantity}</span>
            <span className="basket-item-name">{item.product.name} {displaySize}</span>
          </div>
          <span className="basket-item-price">{priceToString(item.totalPrice)}</span>
        </div>

        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={() => setIsEditModalOpen(false)}
        >
          <ItemModal
            item={{ ...item, isEditing: true }}
            close={() => setIsEditModalOpen(false)}
          />
        </Modal>
      </>
    )
  }

  const Section = () => {
    const categoryMap = { 'starters': 0, 'mains': 1, 'desserts': 2, 'drinks': 3 }

    return (
      <>
        {Object.keys(items).sort((a, b) => categoryMap[a] > categoryMap[b] ? 1 : -1).map(key => {
          if (items[key].length === 0) {
            return null
          }

          return (
            <div className="basket-section" key={key}>
              <span className="basket-section-header">{key}</span>

              <div className="">
                {items[key].map((item, index) => {
                  return <OrderLine item={item} key={`basket-line-${key}-${index}`} />
                })}
              </div>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <>
      <div className="basket margin-top--2">
        <Section />
      </div>

      <div className="basket-footer">
        <div className="basket-location">
          <span className="basket-location-label"> </span>
          <span className="basket-location-value">
            {location ? location.name : " "}
          </span>
        </div>

        <div className="basket-table basket-table--no-top-border">
          <span className="basket-table-label">Table </span>
          <span className="basket-table-number">
            11
          </span>
        </div>

        <div className="basket-actions">
          <button
            type="button"
            className="button--full-width"
            id="btn-place-order"
            onClick={goToCheckout}
            data-cy="checkout-button"
          >
            Checkout - {priceToString(getTotalPrice())}
          </button>
        </div>
      </div>
    </>
  )
}

export default BasketView
