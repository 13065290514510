import React from 'react'

import Loading from '../common/Loading'

const OrderingView = () => {
  return (
    <div className="container">
      <Loading fullscreen={true} />
    </div>
  )
}

export default OrderingView
