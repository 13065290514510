import './hero.scss'

const Hero = ({ imageUrl }) => {
  return (
    <div className='hero__layout'>
      <div
        className='hero__nav-wrapper'
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
      </div>
    </div>
  )
}

export default Hero
