import EventTicket from "./EventTicket"

const EventTicketList = ({ items, heading, hasFee }) => {
  if (!items || items.length === 0) return null

  return (
    <div>
      <h2 className="margin-top--2 capitalise">{heading}</h2>
      {items.map(ticket => <EventTicket ticket={ticket} key={ticket.id} hasFee={hasFee} minOrderQty={ticket.minimumOrderQuantity} />)}
    </div>
  )
}

export default EventTicketList
