import React, { useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'

import { BasketContext } from '../basket/BasketContext'
import priceToString from '../../util/priceToString'

const ItemModal = ({ item, close }) => {
  const [quantity, setQuantity] = useState(item.isEditing ? item.quantity : 1)
  const [selectedSize, setSelectedSize] = useState(item.isEditing ? item.size : item.product.sizes[0])
  const { name, description, sizes } = item.product
  const basketContext = useContext(BasketContext)

  function updateQuantity(action) {
    if (action === "add") {
      setQuantity(quantity + 1)
    }

    if (action === "remove" && quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  function addToOrder() {
    if (item.isEditing) {
      basketContext.updateBasketItem(item, selectedSize, quantity)
    } else {
      basketContext.addToBasket(item, selectedSize, quantity)
    }
    close()
  }

  const RemoveItem = () => {
    if (!item.isEditing) {
      return null
    }

    return (
      <button type="button" className="button--full-width button--secondary margin-top" onClick={() => basketContext.removeItem(item)}>
        Remove item
      </button>
    )
  }

  const Sizes = () => {
    if (sizes === undefined || sizes.length <= 1) {
      return <></>;
    }

    const Size = ({size}) => (
        <div className={size.name === selectedSize.name ? "active" : ""} onClick={() => setSelectedSize(size)}>
          <span>{size.name}</span>
          <span>{priceToString(size.price)}</span>
        </div>
    )
    return (
      <>
        <div className="size-title">
          <span>Size</span>
        </div>
        <div className="modal-item-size-wrapper">
          {sizes.map(size => <Size size={size} key={size.name} />)}
        </div>
      </>
    )
  }

  return (
    <div className="modal">
      <div className="modal-heading">
        <span className="icon" onClick={close}><FontAwesomeIcon icon={faLongArrowAltLeft} /></span>
        <div className="details">
          <span className="title">{name}</span>
          <span className="description">{description}</span>
        </div>
      </div>
      <div className="modal-body">
        <div className="modal-body-inside">
          <Sizes />
          <div className="modal-item-quantity">
            <div className="modal-item-quantity-wrapper">
              <span className="modal-item-quantity-price">{priceToString(selectedSize.price * quantity)}</span>
              <div className="modal-item-quantity-adjust">
                <span className="modal-item-quantity-button" data-cy="item-modal-reduce-quantity" onClick={() => updateQuantity("remove")}>-</span>
                <span className="modal-item-quantity-number" data-cy="item-modal-quantity">{quantity}</span>
                <span className="modal-item-quantity-button" data-cy="item-modal-increase-quantity" onClick={() => updateQuantity("add")}>+</span>
              </div>
            </div>
          </div>
          <div className="modal-actions margin-top--2">
            <button type="button" className="button--full-width margin-top" onClick={addToOrder}>
              {item.isEditing ? 'Update item' : 'Add to basket'}
            </button>
            <RemoveItem />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemModal
