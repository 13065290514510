import { useState, useContext, useEffect } from 'react'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { BasketContext } from 'components/basket/BasketContext'
import Tooltip from 'components/common/Tooltip'
import priceToString from 'util/priceToString'

const EventTicketSize = ({ sizeId, size, item, stockLeft, hasFee, minOrderQty }) => {
  const [quantity, setQuantity] = useState(0)
  const { addToBasket, updateBasketItem, removeItem, getQuantityOfItemInBasket } = useContext(BasketContext)

  useEffect(() => {
    // Check if the size already exists in the basket, if it does, get its quantity
    setQuantity(getQuantityOfItemInBasket(item, size))
  }, [item, size, getQuantityOfItemInBasket])

  function incrementQuantity() {
    const newQty = quantity + 1

    if (stockLeft && stockLeft < newQty) return

    if (quantity === 0) addToBasket(item, size, newQty)
    else updateBasketItem(item, size, newQty)

    setQuantity(newQty)
  }

  function decrementQuantity() {
    const newQty = Math.max(0, quantity - 1)

    if (newQty === 0) removeItem(item, size)
    else updateBasketItem(item, size, newQty)

    setQuantity(newQty)
  }

  return (
    <>
      <div className="event-ticket__purchase" data-testid={sizeId}>
        <div>
          <span className="event-ticket__details__size-name">{size.name}</span>
          {size.description?.length > 0 ? <span data-tip data-for={sizeId} className="margin-left--half" data-testid={`${sizeId}-desc`}><FontAwesomeIcon icon={faInfoCircle} /></span> : null}
          <div>
            <span className="event-ticket__details__price">{priceToString(size.price)}</span>
            <span className="event-ticket__details__price-vat">
              {hasFee && <span>+ booking fee*</span>}
              {!!minOrderQty && <span>. Minimum order quantity is {minOrderQty}.</span>}
            </span>
          </div>
        </div>
        <div className='event-ticket__purchase__button-wrapper'>
          <button onClick={() => decrementQuantity(1)} data-testid="btn-remove">-</button>
          <span className="event-ticket__purchase-qty">{quantity}</span>
          <button onClick={() => incrementQuantity(1)} data-testid="btn-add">+</button>
        </div>
      </div>
      <Tooltip itemId={sizeId} text={size.description} />
    </>
  )
}

export default EventTicketSize
