import { useState } from "react"
import priceToString from "util/priceToString"

const PAYMENT_OPTIONS = { DEPOSIT: 'deposit', FULL: 'full' }

const EventPaymentAmount = ({ onChange, depositAmount, fullAmount, eventDates = [], hasFee, hasDeposit }) => {
  const [paymentOption, setPaymentOption] = useState(PAYMENT_OPTIONS.DEPOSIT)

  const changePaymentOption = (e) => {
    setPaymentOption(e.target.value)
    onChange(e.target.value)
  }

  if (!hasDeposit) return null

  return (
    <>
      <label>Payment option</label>
      <div className="event-payment-amount">
        <div className="event-payment-amount--option">
          <label htmlFor={PAYMENT_OPTIONS.DEPOSIT}>
            <span className="event-payment-amount--option--label">Deposit</span>
            <span className="event-payment-amount--option--value">
              {priceToString(depositAmount)}
              {hasFee && <span className="event-payment__total-vat"> includes fees</span>}
            </span>
          </label>
          <input
            type="radio"
            id={PAYMENT_OPTIONS.DEPOSIT}
            name="payment-option"
            value={PAYMENT_OPTIONS.DEPOSIT}
            onChange={changePaymentOption}
            checked={paymentOption === PAYMENT_OPTIONS.DEPOSIT}
          />
        </div>
        <div className="event-payment-amount--option">
          <label htmlFor={PAYMENT_OPTIONS.FULL}>
            <span className="event-payment-amount--option--label">Full amount</span>
            <span className="event-payment-amount--option--value">
              {priceToString(fullAmount)}
              {hasFee && <span className="event-payment__total-vat"> includes fees</span>}
            </span>
          </label>
          <input
            type="radio"
            id={PAYMENT_OPTIONS.FULL}
            name="payment-option"
            value={PAYMENT_OPTIONS.FULL}
            onChange={changePaymentOption}
            checked={paymentOption === PAYMENT_OPTIONS.FULL}
          />
        </div>
      </div>
      {paymentOption === PAYMENT_OPTIONS.DEPOSIT &&
        <p className="event-payment-date">
          <span className="event-payment-date__final-payment">Full payment required by {new Date(eventDates[0].start).toLocaleDateString()}.</span>
          {hasFee && <span>Future payments will not be charged a booking fee.</span>}
        </p>
      }
      {paymentOption === PAYMENT_OPTIONS.FULL &&
        <br />
      }
    </>
  )
}

export default EventPaymentAmount
