import { useEffect, useState } from "react"
import { useStripe } from '@stripe/react-stripe-js'

const usePayment = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()

  const stripe = useStripe()

  useEffect(() => {
    if (error) setIsLoading(false)
  }, [error])

  const makePayment = async (intentId, card, customer) => {
    setIsLoading(true)
    setError(null)

    if (!intentId || !card || !customer) {
      console.error('Missing argument when calling makePayment')
      setError({ message: 'There was an error with creating your order. Please try again.' })
      return
    }

    // Create the payment method
    const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
      billing_details: {
        name: customer.name,
        email: customer.email,
        phone: customer.phone,
      }
    })

    if (paymentMethodError) {
      setError(paymentMethodError)
      return
    }

    // Process payment
    const stripeRes = await stripe.confirmCardPayment(intentId, {
      payment_method: paymentMethod.id,
    })

    if (stripeRes.error) {
      setError(stripeRes.error)
      return
    }

    if (stripeRes?.paymentIntent?.status?.toLowerCase() === 'succeeded') {
      return stripeRes
    } else {
      setError({ message: 'There was an error with creating your order. Please try again.' })
    }

    return stripeRes
  }

  return {
    isLoading,
    error,
    makePayment,
  }
}

export default usePayment
