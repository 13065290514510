import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import priceToString from '../../util/priceToString'

const AllItems = ({ sections, openModal }) => {
  // TODO - merge with other item card
  const ItemCard = ({ item }) => {
    const cardClass = classNames('item-card', {
      'item-card--disabled': !item.product.available,
    })

    const outOfStockClass = classNames('out-of-stock', {
      'display--none': item.product.available,
    })

    function checkIfCanOpenModal() {
      if (item.product.available) {
        openModal(item)
      }

      return false
    }

    return (
      <div className={cardClass} onClick={() => checkIfCanOpenModal()}>
        <span className="title">{item.product.name}</span>
        <span className="price">{priceToString(item.product.sizes[0].price)}</span>
        <span className={outOfStockClass}><FontAwesomeIcon icon={faTimesCircle} /> Out of stock</span>
      </div>
    )
  }

  return (
    <div className="order-page-all-wrapper">
      {sections.map((s, i) => {
        // Ignore 'all'
        if (i === 0) return null

        return (
          <React.Fragment key={s.title}>
            <h2>{s.title}</h2>
            <div className="items-wrapper">
              {s.items.map(item => {
                return <ItemCard item={item} key={item.itemId} />
              })}
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default AllItems
