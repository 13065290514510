import React from 'react'
import classNames from 'classnames'
import { Link, useRouteMatch } from 'react-router-dom'

const LocalNav = ({ sections }) => {
  const { url } = useRouteMatch()

  return (
    <div className="local-nav">
      <ul>
        {sections.map(section => {
          const navClass = classNames({
            'is-active': section.href === `${url}`,
          })

          return (
            <li className={navClass} key={section.title}>
              <Link to={section.href}>{section.title}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default LocalNav
